<template>
  <div>
    <titulo-relatorio-historico-escolar />
    <cabecalho
      title="ACADEMICO.IMPRIMIR_RELATORIOS_HISTORICO_ESCOLAR"
      description="Realiza a impressão de relatório disciplinas"
      linkRetornar="academico"
    />
    <b-row>
      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="anoLetivoId"
          v-model="selecionados.anoLetivoId"
          :label="$t('ACADEMICO.ANO_LETIVO')"
          :options="opcoes.anosLetivos"
          required
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="cursoId"
          v-model="selecionados.cursoId"
          :label="$t('ACADEMICO.CURSO')"
          :options="opcoes.cursos"
          :disabled="desabilitar.curso"
          required
        />
      </b-col>
      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="grupoTurmaId"
          v-model="selecionados.grupoTurmaId"
          :label="$t('ACADEMICO.GRUPO_TURMA')"
          :options="opcoes.gruposTurma"
          :disabled="desabilitar.grupoTurma"
          required
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="turmaId"
          v-model="selecionados.turmaId"
          :label="$t('ACADEMICO.TURMA')"
          :options="opcoes.turmas"
          :disabled="desabilitar.turma"
          required
        />
      </b-col>
      <b-col cols="12" md="auto">
        <b-button
          class="w-100 botao-acao-filtro mt-3"
          variant="info"
          @click="listarAluno"
        >
          {{ $t('ACADEMICO.LISTAR_ALUNOS') }}
        </b-button>
      </b-col>
    </b-row>
    <tabela-alunos
      :alunos="alunos"
      :cursoId="selecionados.cursoId ? selecionados.cursoId.id : null"
      class="mt-5"
    />
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import helpers from '@/common/utils/helpers';
import TabelaAlunos from './TabelaAlunos';

// Services:
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import PessoaService from '@/common/services/pessoa/pessoa.service';
import CursoService from '@/common/services/academico/curso.service';
import GrupoTurmaService from '@/common/services/academico/gruposTurma.service';
import TurmaService from '@/common/services/academico/turma.service';

// Components:
import { InputSelectMultiple } from '@/components/inputs';
import mensagem from '@/common/utils/mensagem';
import TituloRelatorioHistoricoEscolar from '@/views/academico/relatorios/relatorio-historico-escolar/TituloRelatorioHistoricoEscolar';

export default {
  components: {
    TituloRelatorioHistoricoEscolar,
    InputSelectMultiple,
    TabelaAlunos,
  },
  data() {
    return {
      selecionados: {
        grupoTurmaId: null,
        anoLetivoId: null,
        turmaId: null,
        cursoId: null,
      },
      opcoes: {
        anosLetivos: [],
        cursos: [],
        gruposTurma: [],
        turmas: [],
      },
      alunos: [],
      desabilitar: {
        curso: true,
        grupoTurma: true,
        turma: true,
      },
    };
  },
  watch: {
    'selecionados.anoLetivoId': {
      handler(anoLetivoId) {
        if (anoLetivoId) {
          this.getCursos(anoLetivoId.id);
        } else {
          this.opcoes.cursos = [];
          this.opcoes.gruposTurma = [];
          this.opcoes.turmas = [];
          this.desabilitar.curso = true;
          this.desabilitar.grupoTurma = true;
          this.desabilitar.turma = true;
        }
        this.selecionados.cursoId = null;
        this.selecionados.grupoTurmaId = null;
        this.selecionados.turmaId = null;
      },
    },
    'selecionados.cursoId': {
      handler(cursoId) {
        if (cursoId) {
          this.getGruposTurmaPorCursoId(cursoId);
        } else {
          this.desabilitar.grupoTurma = true;
          this.desabilitar.turma = true;
          this.opcoes.gruposTurma = [];
          this.opcoes.turmas = [];
        }
        this.selecionados.grupoTurmaId = null;
        this.selecionados.turmaId = null;
      },
    },
    'selecionados.grupoTurmaId': {
      handler(turmaId) {
        if (turmaId) {
          this.getTurmaPorGrupoTurmaIdAnoLetivoId();
        } else {
          this.desabilitar.turma = true;
          this.opcoes.turmas = [];
        }
        this.selecionados.turmaId = null;
      },
    },
    // 'selecionados.grupoTurmaId': 'getTurmaPorGrupoTurmaIdAnoLetivoId',
    // 'selecionados.cursoId': 'getGruposTurmaPorCursoId',
    // 'selecionados.anoLetivoId': [
    //   'getCursos',
    //   'getTurmaPorGrupoTurmaIdAnoLetivoId',
    // ],
  },
  mounted() {
    this.getAnosLetivos();
  },
  methods: {
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    listarAluno() {
      if (!this.validarFormulario()) return;

      let { cursoId, turmaId, anoLetivoId } = this.selecionados;

      let formMontado = {
        cursoId: cursoId.id,
        turmaId: turmaId.id,
        anoLetivoId: anoLetivoId.id,
      };

      this.$store.dispatch(START_LOADING);
      PessoaService.obterAlunosPorFiltro(formMontado)
        .then(({ data }) => {
          this.alunos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getAnosLetivos() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar()
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.ano,
              value: row.id,
            };
          });

          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getCursos(anoLetivoId) {
      this.$store.dispatch(START_LOADING);
      CursoService.obterPorAnoLetivo(anoLetivoId)
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.descricao,
              value: row.id,
            };
          });

          this.opcoes.cursos = data;
          this.desabilitar.curso = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getGruposTurmaPorCursoId(curso) {
      if (this.selecionados.grupoTurmaId !== null) {
        this.selecionados.grupoTurmaId.value = '';
        this.selecionados.grupoTurmaId.text = '';

        this.selecionados.turmaId.value = '';
        this.selecionados.turmaId.text = '';

        this.desabilitar.turma = true;
      }

      this.$store.dispatch(START_LOADING);
      GrupoTurmaService.obterTodos({ cursoId: curso.id })
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.descricao,
              value: row.id,
            };
          });

          this.opcoes.gruposTurma = data;
          this.desabilitar.grupoTurma = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTurmaPorGrupoTurmaIdAnoLetivoId() {
      if (this.selecionados.turmaId !== null) {
        this.selecionados.turmaId.value = '';
        this.selecionados.turmaId.text = '';
      }
      const anoLetivoId = this.selecionados.anoLetivoId?.id;
      const grupoTurmaId = this.selecionados.grupoTurmaId?.id;

      if (anoLetivoId !== null && grupoTurmaId !== undefined) {
        this.desabilitar.turma = false;
      }

      this.$store.dispatch(START_LOADING);
      TurmaService.buscarPorGrupoTurmaIdAnoLetivoId({
        grupoTurmaId: this.selecionados.grupoTurmaId?.id,
        anoLetivoId: this.selecionados.anoLetivoId?.id,
      })
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.descricao,
              value: row.id,
            };
          });

          this.opcoes.turmas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>

<style>
.ajuste-fino-btn {
  margin-top: 45px;
}
</style>
