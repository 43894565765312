<template>
  <div>
    <b-table
      :items="alunos"
      :fields="fields"
      show-empty
      :empty-text="$t('ACADEMICO_MATRICULAS.NENHUM_MATRICULA_REGISTRADA')"
      head-variant="light"
      responsive
      striped
      hover
    >
      <template #cell(acoes)="item">
        <div class="flex">
          <b-dropdown variant="outline-default">
            <!-- Relatórios -->
            <b-dropdown-item @click="imprimirHistoricoEscolar(item.item, 2)">
              {{ 'Imprimir certificado de conclusão' }}
            </b-dropdown-item>
            <b-dropdown-item @click="imprimirHistoricoEscolar(item.item, 1)">
              {{ $t('ACADEMICO_MATRICULAS.IMPRIMIR_HISTORICO_ESCOLAR') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
// Utils & Aux:
import Permissoes from '@/components/mixins/permissoes';
import helpers from '@/common/utils/helpers';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import pdf from '@/common/utils/pdf';

// services
import HistoricoEscolarService from '@/common/services/academico/historicoEscolar.service';

export default {
  mixins: [Permissoes],
  components: {},
  props: {
    alunos: { type: Array, default: Array },
    cursoId: { type: String, default: String },
  },
  data() {
    return {
      fields: [
        {
          key: 'codigoMatricula',
          label: this.$t('ACADEMICO_MATRICULAS.CODIGO_MATRICULA'),
        },
        {
          key: 'nomeAluno',
          label: this.$t('ACADEMICO_MATRICULAS.ALUNO'),
        },
        {
          key: 'acoes',
          label: this.$t('ACADEMICO_MATRICULAS.ACOES'),
        },
      ],
    };
  },
  methods: {
    imprimirHistoricoEscolar({ matriculaId }, layoutHistoricoEscolar) {
      if (!this.validarFormulario()) return;

      this.$store.dispatch(START_LOADING);

      HistoricoEscolarService.imprimir({
        matriculaId,
        cursoId: this.cursoId,
        layoutHistoricoEscolar,
      })
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            let blob = new Blob([bytes], { type: 'application/pdf' });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
  },
};
</script>
